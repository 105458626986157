<template>
    <div>
        <div
            class="border-0 m-0 d-flex flex-wrap align-items-center mt-5"
            :class="mobile ? 'justify-content-center' : 'justify-content-between'"
        >
            <div
                class="border-0 m-0 d-flex flex-wrap align-items-center"
                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                style="width:100%"
            >
                <b-form-group :style="mobile ? 'width:100%' : 'width:60%'" class="m-0" label-for="filterInput">
                    <b-input-group>
                        <b-form-input v-model.lazy="search_filter" type="search" id="filterInput" placeholder="Rechercher" />
                        <b-input-group-append>
                            <b-button :disabled="!search_filter" @click="search_filter = ''">Effacer</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <div>
                    <button
                        v-if="selected.length >= 1"
                        v-b-tooltip.hover.top="'Valider toutes les demandes d\'inscription sélectionnées'"
                        class="btn btn-clean border border-success svg-icon svg-icon-success text-success"
                        @click="acceptSelectedUsers"
                    >
                        <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                        Valider
                    </button>
                    <button
                        v-if="selected.length >= 1"
                        v-b-tooltip.hover.top="'Refuser toutes les demandes d\'inscription sélectionnées'"
                        class="btn btn-clean border border-danger svg-icon svg-icon-danger text-danger ml-3"
                        @click="deleteSelectedUsers"
                    >
                        <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                        Refuser
                    </button>
                </div>
            </div>

            <b-table
                :items="queue.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="search_filter"
                @filtered="onFiltered"
                responsive
                ref="selectableTable"
                select-mode="multi"
                selectable
                @row-selected="onRowSelected"
                hover
                show-empty
                no-border-collapse
                style="vertical-align: middle; text-align: center"
                class="mx-0 p-0 my-5"
            >
                <template #head(selected)>
                    <div
                        v-if="queue.data.length"
                        @click="selected.length >= 1 ? $refs.selectableTable.clearSelected() : $refs.selectableTable.selectAllRows()"
                    >
                        <button v-if="selected.length >= 1" v-b-tooltip.hover.left="'Vider la sélection'" class="h3 m-0 text-danger">
                            &#x2612;
                        </button>
                        <button v-b-tooltip.hover.left="'Sélectionner toutes les demandes de la page'" v-else class="h3 m-0">
                            &#x2610;
                        </button>
                    </div>
                </template>
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span v-b-tooltip.hover.left="'Ne plus sélectionner cette ligne'" class="text-primary h4 m-0">&#x2611;</span>
                    </template>
                    <template v-else>
                        <span v-b-tooltip.hover.left="'Sélectionner cette ligne'" class="h4 m-0">&#x2610;</span>
                    </template>
                </template>
                <template #cell(user.date_of_birth)="row">
                    {{ formatDate(row.item.user.date_of_birth) }}
                </template>
                <template #cell(customer.name)="row">
                    <div>
                        <p class="m-0">{{ row.item.customer.name }}</p>
                        <span class="text-muted">
                            {{ `(${row.item.customer.code})` }}
                        </span>
                    </div>
                </template>
                <template #cell(created_at)="row">
                    <span v-if="row.item.created_at"> {{ formatDate(row.item.created_at) }} </span>
                </template>
                <template #cell(Action)="row">
                    <div class="d-flex flex-row align-items-center w-25">
                        <button
                            class="btn btn-clean btn-md svg-icon svg-icon-success"
                            v-b-tooltip.hover.top="'Valider l\'inscription'"
                            @click="acceptUser(row.item.user, row.item.id)"
                        >
                            <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                        </button>
                        <button
                            v-b-tooltip.hover.top="'Refuser l\'inscription'"
                            class="btn btn-clean btn-md svg-icon svg-icon-lg svg-icon-danger"
                            @click="deleteUser(row.item.id)"
                        >
                            <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                        </button>
                    </div>
                </template>
                <template #empty>
                    <div>
                        <h5>La liste d'attente est vide</h5>
                    </div>
                </template>
                <template #emptyfiltered>
                    <div>
                        <h6>Aucun résultat pour cette recherche</h6>
                    </div>
                </template>
            </b-table>
            <b-row
                class="d-flex align-items-center"
                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                style="width:100%"
            >
                <b-col sm="5" md="4">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" />
                </b-col>
                <div>
                    <ul class="pagination" :class="mobile ? 'mt-2' : ''">
                        <li class="page-item active">
                            <a class="page-link bg-primary">
                                Nombre de résultats : {{ search_filter ? totalRows : queue ? queue.data.length : '' }}
                            </a>
                        </li>
                    </ul>
                </div>
            </b-row>
        </div>
    </div>
</template>

<script>
import { LIST_QUEUE } from '@/core/services/store/api/client-classe-queue.service';
import ApiService from '@/core/services/api.service';
import { mapGetters } from 'vuex';
import moment from 'moment';
//import moment from 'moment';

export default {
    props: {
        classCustomerId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, { value: 100, text: 'Le plus possible' }],
            search_filter: null,
            selected: [],
            isBusy: false,
            sortBy: 'title',
            sortDesc: false,
            fields: [
                { key: 'selected', label: '', sortable: false },
                { key: 'user.firstname', label: 'Prénom', sortable: true },
                { key: 'user.lastname', label: 'Nom', sortable: true },
                { key: 'user.date_of_birth', label: 'Date de naissance', sortable: true },
                { key: 'customer.name', label: 'Établissement de rattachement', sortable: true },
                { key: 'created_at', label: 'Date de la demande', sortable: true },
                { key: 'Action', label: '', thStyle: 'width: 5%', sortable: false }
            ]
        };
    },
    mounted() {
        this.getQueue();
    },
    computed: {
        ...mapGetters({
            currentUser: 'currentUser',
            queue: 'getQueue'
        }),
        mobile() {
            return !(window.screen.availWidth > 992);
        }
    },
    watch: {
        currentPage: function() {
            return this.getQueue();
        }
    },
    methods: {
        getQueue() {
            let params = {
                classCustomerId: this.classCustomerId,
                params: {
                    page: this.currentPage,
                    'filter[classe.title]': this.search_filter
                }
            };
            this.$store.dispatch(LIST_QUEUE, params);
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        acceptUser(user, queue_id) {
            ApiService.put(`academy/clientclasses/queue/${queue_id}`)
                .then(() => {
                    if (user) {
                        this.$bvToast.toast(`Le stagiaire ${user.firstname} ${user.lastname.toUpperCase()} a été inscrit avec succès`, {
                            title: `Inscription`,
                            variant: 'success',
                            solid: true
                        });
                    }
                    this.getQueue();
                })
                .catch(error => {
                    if (error.response.data.message) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: `Error lors de l'inscription`,
                            variant: 'danger',
                            solid: true
                        });
                    }
                });
        },
        deleteUser(queue_id, noConfirm) {
            if (!noConfirm) {
                this.$bvModal
                    .msgBoxConfirm("Êtes-vous sûr de vouloir refuser l'inscription ?", {
                        title: 'Veuillez confirmer',
                        size: 'sm',
                        buttonSize: 'md',
                        okVariant: 'danger',
                        okTitle: 'Oui, je suis sûre',
                        cancelTitle: 'Non',
                        footerClass: 'p-2 d-flex justify-content-around',
                        bodyClass: 'font-weight-bold',
                        hideHeaderClose: false
                    })
                    .then(value => {
                        if (value) {
                            ApiService.delete(`academy/clientclasses/queue/${queue_id}`).then(() => {
                                this.getQueue();
                            });
                        }
                    });
            } else {
                ApiService.delete(`academy/clientclasses/queue/${queue_id}`).then(() => {
                    this.getQueue();
                });
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onRowSelected(items) {
            this.selected = items;
        },
        deleteSelectedUsers() {
            this.$bvModal
                .msgBoxConfirm('Êtes-vous sûr de vouloir refuser les inscriptions sélectionnées ?', {
                    title: 'Veuillez confirmer',
                    size: 'sm',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    okTitle: 'Oui, je suis sûre',
                    cancelTitle: 'Non',
                    footerClass: 'p-2 d-flex justify-content-around',
                    bodyClass: 'font-weight-bold',
                    hideHeaderClose: false
                })
                .then(value => {
                    if (value) {
                        this.selected.map(queue_item => {
                            this.deleteUser(queue_item.id, true);
                        });
                    }
                });
        },
        acceptSelectedUsers() {
            this.selected.map(async queue_item => {
                await this.acceptUser(false, queue_item.id);
            });
            /*
            if (this.selected === []) {
                this.$bvToast.toast(`Les ${this.selected.length} stagiaires ont était inscrit avec succès`, {
                    title: `Inscription`,
                    variant: 'success',
                    solid: true
                });
            } else {
                this.$bvToast.toast(
                    `Une erreur s'est produite durant l'inscription des ${this.selected.length} stagiaires, vérifié s'ils ont bien tous été inscrit`,
                    {
                        title: `Inscription`,
                        variant: 'warning',
                        solid: true
                    }
                );
            }
             */
        }
    }
};
</script>
<style>
table th,
table td {
    vertical-align: middle !important;
}
</style>
